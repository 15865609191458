import React from "react";

/*** COMPONENTS ***/
import Main from "../../components/main";
import SEO from "../../components/seo";
import "../../styles/project.css";
import wkitHeader from "../../images/wkit.png";

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes";

function openWKIT() {
  window.open("https://weknowit.se/", "_blank");
}

const wkitPage = () => (
  <Main>
    <SEO title="We Know IT" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-WKIT" src={wkitHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">WE KNOW IT</p>
          <p class="project-header-text-grey">WEB DEVELOPMENT</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Employment</p>
        <p class="project-bulletpoint-text"> &#9642; Part-Time</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2020-11-30</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          We Know IT is a consulting company driven by students. My assignments
          have been in React and Firebase where I've been helping clients to
          create websites. I have worked in a team together with a project
          leader, other consultants and an advisor. Together we help each other
          and bring the work forward. I find this a good opportunity for me to
          try the consultant role, develop my programming skills and to manage a
          client order and its specifications.
          <br />
          <br />
          You can checkout their{" "}
          <button class="project-text-link-style" onClick={() => openWKIT()}>
            webpage
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.WORK}>
          &#8592; Back to work
        </a>
      </div>
    </div>
  </Main>
);

export default wkitPage;
